import React from 'react';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { Layout, Single, TitleAndMeta } from '../components';

import { formatDate, linkResolver } from '../utils';

const Page = ({
  data: {
    prismic: { allNotes },
  },
}) => {
  const edge = allNotes.edges.slice(0, 1).pop();
  if (!edge) return null;

  const doc = edge.node;

  const formattedDate = formatDate(doc.publication_date);

  return (
    <Layout>
      <TitleAndMeta title={doc.title[0].text}></TitleAndMeta>
      <Single
        meta={
          <ul>
            <li>{formattedDate}</li>
            {doc.languages && (
              <li>
                Language:{' '}
                {doc.languages
                  .map(({ language1 }) => language1)
                  .filter(lang => lang !== null)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(lang => lang.name)
                  .reduce(
                    (acc, x) => (acc === null ? [x] : [acc, ', ', x]),
                    null
                  )}
              </li>
            )}
          </ul>
        }
      >
        {RichText.render(doc.title)}
        {RichText.render(doc.content)}
      </Single>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query NoteQuery($uid: String) {
    prismic {
      allNotes(uid: $uid) {
        edges {
          node {
            title
            content
            languages {
              language1 {
                ... on PRISMIC_Language {
                  name
                  code
                  _meta {
                    type
                  }
                }
              }
            }
            publication_date
          }
        }
      }
    }
  }
`;
